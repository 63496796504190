import Toastify from 'toastify-js'

export const useNotifications = (
  type = 'info',
  message = '[response.msg]',
  title = null,
  duration = 3000,
  callback = () => {},
) => {
  const types = ['info', 'success', 'warning', 'error']
  // const titles = {
  //   "info": Translator.trans("push_notification.types.info"),
  //   "success": Translator.trans("push_notification.types.info"),
  //   "warning": Translator.trans("push_notification.types.info"),
  //   "error": Translator.trans("push_notification.types.info"),
  // };

  Toastify({
    // text: `<h4>${title ? title : titles[type]}</h4><div>${msg}</div>`,
    text: `<div>${message}</div>`,
    escapeMarkup: false,
    duration,
    close: true,
    gravity: 'top',
    position: 'right',
    stopOnFocus: true,
    className: `notification-${types.includes(type) ? type : 'info'}`,
    onClick: callback,
  }).showToast()
}

export const showToastNotification = useNotifications
